import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import {
  CustomAppBar,
  ResetPassword,
  ForgotPassword,
  SignIn,
} from "@shared/components/lib/index";
import conf from "common-utils/conf";
import { getCarrierAdminPages, getCarrierPages } from "./routers";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI } from "common-utils/utils/api";

export default function CarrierApp() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userType, setUserType] = useState("");
  const history = useHistory();
  const [auth, setAuth] = useLocalStorage("auth", null);

  useEffect(() => {
    if (!!auth) {
      authUser();
    } else {
      cleanState();
    }
  }, [auth]);

  const signOut = async () => {
    await postRequestUI("/logout");
    cleanState();
    history.push("/login");
  };

  const cleanState = () => {
    setUserType(null);
    setAnchorEl(null);
    setAuth(null);
  };
  const authUser = async () => {
    try {
      const { role, email } = auth;
      if (!role.userType) {
        signOut();
        return;
      }
      if (role.userType !== "carrier" && role.userType !== "carrierAdmin") {
        signOut();
        return;
      }
      setUserType(role.userType);
      setTimeout(function () {
        window.Trengo.contact_data = {
          email: email,
          name: email,
          custom_fields: [
            {
              field_id: email,
              user_email: email,
            },
          ],
        };
        if (window.Trengo.Api && window.Trengo.Api.Widget) {
          window.Trengo.Api.Widget.render();
        }
      }, 2500);
      return;
    } catch (error) {
      signOut();
    }
  };

  const redirect = () => {
    if (auth) {
      return <Redirect to={`/scheduled`} />;
    }
    return <Redirect to={`/login`} />;
  };
  const pages =
    userType === "carrier"
      ? getCarrierPages({ userType })
      : getCarrierAdminPages({ userType });

  return (
    <>
      {!auth && (
        <Switch>
          <Route path={"/forgot-password/:token?"} component={ForgotPassword} />
          <Route
            path={"/reset-password/:token?"}
            component={() => <ResetPassword appUrl={conf.carrier} />}
          />
          <Route exact path={"/login"} component={SignIn} />
          <Route path="*" render={redirect} />
        </Switch>
      )}
      {(userType === "carrier" || userType === "carrierAdmin") && (
        <>
          <CustomAppBar
            pages={pages}
            appName={"C"}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            mobileOpen={mobileOpen}
            setMobile={setMobileOpen}
            handleLogout={signOut}
          />
          <Switch>
            <main>
              {pages.map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  render={route.component}
                />
              ))}
              <Route path="*" render={redirect} />
            </main>
          </Switch>
        </>
      )}
    </>
  );
}
